import { useEffect, useState } from "react";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,query,where,
  addDoc,
} from "firebase/firestore";
import PhoneInput from "react-phone-number-input";
import "./numberinput.css"; // Import your custom styles

function BookCar({ carName }) {  

  const [thisMonth, setThisMonth] = useState('');
  const [selectedCar, setSelectedCar] = useState(carName);
  const [messages, setMessages] = useState([]);
  const [itemId, setItemId] = useState(null);


  useEffect(() => {
    const fetchItemId = async () => {
      try {
        // Create a query to search the carsdata collection by carname
        const q = query(collection(db, "carsdata"), where("name", "==", selectedCar));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setItemId(doc.id); // Set the item ID
        } else {
          console.log("No matching documents in carsdata collection.");
        }
      } catch (error) {
        console.error("Error fetching item ID:", error);
      }
    };

    fetchItemId();
  }, [selectedCar]);

  useEffect(() => {
    if (itemId) {
      const fetchData = async () => {
        try {
          // Create a reference to the sub-collection within the specific document
          const subCollectionRef = collection(db, "carsdata", itemId, thisMonth);

          const unsubscribe = onSnapshot(subCollectionRef, (snapshot) => {
            const filteredData = snapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
            setMessages(filteredData);
          });

          return () => {
            // Unsubscribe when component unmounts
            unsubscribe();
          };
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [db, itemId, selectedCar, setMessages, thisMonth]); 


  const [carTypes, setCarTypes] = useState([]);
 
  const [carImgUrl, setCarImgUrl] = useState("");
  const [insuranceData, setinsuranceData] = useState("");
  const [carData, setCarData] = useState([]);
 

  useEffect(() => {
    setCarImgUrl('')
  }, [carName]);
  
  
    useEffect(() => {
    
      console.log(carName)
    setSelectedCar(carName)
    setCarData('')
        
        const handleSelectChange = async () => {
          // Find the selected car in the data and set its image URL
          const selectedCarData = carData.find((car) => car.name === carName);
          if (selectedCarData) {
            setCarImgUrl(selectedCarData.img);
          } else {
            // If the data is not in the state, fetch it from Firestore
            try {
              const querySnapshot = await getDocs(collection(db, "carsdata"));
              const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
  
              setCarData(data);
              const newSelectedCarData = data.find(
                (car) => car.name === carName
              );
              if (newSelectedCarData) {
                setCarImgUrl(newSelectedCarData.img);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }
        };
    handleSelectChange()
      
    console.log('Car Changed')
    
  }, [carName]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "carsdata"));
          const data = querySnapshot.docs.map((doc) => doc.data());
          setCarData(data); // Update this line
          setCarTypes(data.map((car) => car.name));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, [carName]);
  
  
    useEffect(() => {

      const fetchData = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "carsdata"));
          const data = querySnapshot.docs.map((doc) => doc.data());
          setCarData(data); // Update this line
          setCarTypes(data.map((car) => car.name));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();

     


/* 
       const fetchData = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "carsdata"));
          const data = querySnapshot.docs.map((doc) => doc.data());
          setCarData(data);
          const carNames = data.map((car) => car.name);
          const carInsData = data.map((car) => car.insdata);
          
          // Join the carInsData array into a single string
          const insuranceString = carInsData.join(', ');
          setinsuranceData(insuranceString);
          
          // Set the carTypes state variable
          setCarTypes(carNames);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      
      fetchData();

    const fetchDatas = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCarData(data); // Update this line
        setinsuranceData(data.map((car) => car.insdata));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDatas();
*/
    
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const filteredCar = querySnapshot.docs.find(doc => doc.data().name === selectedCar);
        
        if (filteredCar) {
          const insData = filteredCar.data().insdata;
          setinsuranceData(insData);
        } else {
          console.log("Car not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    const fetchDataz = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const filteredCar = querySnapshot.docs.find(doc => doc.data().name === selectedCar);
        
        if (filteredCar) {
          const imgData = filteredCar.data().img;
          setCarImgUrl(imgData);
        } else {
          console.log("Car not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDataz();
}, [selectedCar]);

  const [insurance, setinsurance] = useState(insuranceData);

  useEffect(() => {
setinsurance(insuranceData)
  }, [insuranceData]);



  const handleSelectChange = async (event) => {
    const selectedCarName = event.target.value;
    setSelectedCar(selectedCarName);

    // Find the selected car in the data and set its image URL
    const selectedCarData = carData.find((car) => car.name === selectedCarName);
    if (selectedCarData) {
      setCarImgUrl(selectedCarData.img);
    } else {
      // If the data is not in the state, fetch it from Firestore
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCarData(data);
        const newSelectedCarData = data.find(
          (car) => car.name === selectedCarName
        );
        if (newSelectedCarData) {
          setCarImgUrl(newSelectedCarData.img);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  //////////////

  const [tyreInsurance, setTyreInsurance] = useState(false);
  const [glassInsurance, setGlassInsurance] = useState(false);
  const [underbodyInsurance, setUnderbodyInsurance] = useState(false);


  const [tyre, setTyre] = useState('');
  const [glass, setGlass] = useState('');
  const [underbody, setUnderbody] = useState('');


  useEffect(() => {

   if(tyreInsurance==true)
    {

      setTyre('tyre')
    }
    else setTyre('')

  if(glassInsurance==true)
    {

      setGlass('glass')
    }
    else setGlass('')

    if(underbodyInsurance==true)
      {
  
        setUnderbody('underbody')
      }
      else setUnderbody('')
  

  }, [tyreInsurance,glassInsurance,underbodyInsurance]);


  console.log(tyre)  
  console.log(glass) 
  console.log(underbody) 


  const [modal, setModal] = useState(false); //  class - active-modal
  // booking car
  const [carType, setCarType] = useState(carName);
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");
  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  //const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [adddriver, Adriver] = useState("No");
  const [pickingTime, setPickingTime] = useState("");
  const [droppingTime, setDroppingTime] = useState("");
 const [babyseat, setbabyseat] = useState("");
 const [seattype, setseattype] = useState("No");
 

 useEffect(() => {
  if (age >= 21 && age <= 24) {
    window.alert(`You are a Young Driver and you'll pay 5 Euro per day. (Max till 100 Euro).`);
  } else if (age >= 71 && age <= 75) {
     window.alert(`You are a senior driver and you'll pay 5 Euro per day. (Max till 100 Euro).`);
  } 
}, [age]);


  // taking value of modal inputs
  const handleseatnumber = (e) => {
    setbabyseat(e.target.value);
  };

  const handleseatypet = (e) => {
    setseattype(e.target.value);
  };

  const handleinsurance = (e) => {
    setinsurance(e.target.value);
  };


  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  // const handleAddress = (e) => {
  //   setAddress(e.target.value);
  //  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleZip = (e) => {
    setZipCode(e.target.value);
  };

  const Addingdriver = (e) => {
    Adriver(e.target.value);
  };

  // open modal when all inputs are fulfilled
  
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
  
    // Validate input fields

    if (pickingTime <= "07:59" || pickingTime >= "22:01") {
      window.alert("Our office hours are 08:00 to 22:00, please choose your pickup/dropoff time accordingly!");
      return;
    }

     if (droppingTime <= "07:59" || droppingTime >= "22:01") {
      window.alert("Our office hours are 08:00 to 22:00, please choose your pickup/dropoff time accordingly!");
      return;
    }

    if (
      !pickUp ||
      !dropOff ||
      !pickTime ||
      !dropTime ||
      !droppingTime ||
      !selectedCar ||
      !pickingTime
    ) 
    
    
    {
      errorMsg.style.display = "flex";
    } else if (new Date(pickTime) >= new Date(dropTime)) {
      // Check if pickTime is not before dropTime
      window.alert("Pick-up date should be before Drop-off date. Please correct dates and click proceed!");
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking

  const handleTimeChange = (e) => {
    setPickingTime(e.target.value);
    console.log(pickingTime)
   
  };

  const handleTimeChange2 = (e) => {
    setDroppingTime(e.target.value);
   
  };

  const convertTo12HourFormat = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const parsedHour = parseInt(hour, 10);
    const ampm = parsedHour >= 12 ? "PM" : "AM";
    const formattedHour = parsedHour % 12 === 0 ? 12 : parsedHour % 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };

console.log(selectedCar)


  const postBooking = async () => {
    try {
      // Condition to validate Booking Form Fields

      if (!name || !lastName || !age || !phone || !email || !city)
        window.alert("Error: Fill All Fields!");
      else {

        if (phone.length > 0 && phone.length < 6) {
          window.alert('Please recheck your phone number, as digits are less.');
        }

        else{

        const formattedTime = convertTo12HourFormat(pickingTime);
        const formattedTime1 = convertTo12HourFormat(droppingTime);
        // const pickupFieldValue = `${formattedTime} ${pickTime}`;

        console.log('Testing')
        console.log(selectedCar)
        console.log(insurance)

        await db.collection("bookingsdata").add({
          carname: selectedCar,
          pickup: pickUp,
          dropoff: dropOff,
          picktime: pickTime,
          droptime: dropTime,
          pickingtime: formattedTime,
          droppingtime: formattedTime1,
          name: `${name} ${lastName}`,
          //lastName: lastName,
          phone: phone,
          age: age,
          email: email,
          seattype: seattype,
          seatnumber: babyseat,
          adddriver: adddriver,
          insurance: insurance,
          tyre: tyre,
          glass: glass,
          underbody:underbody,
          city: city,
         // zip: zipcode,
        });

        // Log a message to the console if the document was added successfully
        console.log("Booking Details Posted!");

        // Show an alert to the user
        window.alert("Your booking is saved!");

        // Close the modal
        setModal(false);

        // Display the "booking done" message
        const doneMsg = document.querySelector(".booking-done");
        doneMsg.style.display = "flex";

        // Clear the form fields
        setPickUp("");
        setDropOff("");
        setPickTime("");
        setDropTime("");
        setCarType("");
        setName("");
        setLastName("");
        setPhone("");
        setAge("");
        setEmail("");
        // setAddress("");
        setCity("");
        setPickingTime("");
        setDroppingTime("");
        setZipCode("");
      }
    }
    } catch (error) {
      // Log any errors to the console
      console.error(error);
    }
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    postBooking();
  };

  // taking value of booking inputs
  const handleCar = (e) => {
    const selectedCarName = e.target.value;
    setCarType(selectedCarName);

    // Find the selected car in the data and set its image URL
    const selectedCarData = carData.find((car) => car.name === selectedCarName);
    if (selectedCarData) {
      setCarImg(selectedCarData.img);
     
    }
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

  const formattedTimes = convertTo12HourFormat(pickingTime);
  const formattedTimes1 = convertTo12HourFormat(droppingTime);
  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };


  
 
  useEffect(() => {
    const month = new Date(pickTime).getMonth(); // getMonth() returns 0 for January, 1 for February, etc.
    console.log(month)

    if (month >= 3 && month <= 9) { // April (3) to October (9)
      setThisMonth('highseason');
    } else { // November (10) to March (2)
      setThisMonth('lowseason');
    }

    console.log(thisMonth)
  }, [pickTime]);


  return (
    <>
      <section id="booking-section" className="book-section">
        {/* overlay */}
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>

        <div className="plan-container__title" style={{ paddingTop: "30px" }}>
          <h2>Reserve Your Car : {carName}  </h2>
        </div>

        <div className="container">
          <div className="book-content">
            <div
              className="book-content__box"
              style={{
                backgroundColor: "#48dbfb",
                backgroundImage:
                  "linear-gradient(315deg, #48dbfb 0%, #d3d3d3 74%)",
              }}
            >
              <h2>Book a car </h2>
              <p className="error-message">
                All fields required! <i className="fa-solid fa-xmark"></i>
              </p>
              <p className="booking-done">
                Your booking is saved! You will recieve a confirmation email from us soon!{" "}
                <i onClick={hideMessage} className="fa-solid fa-xmark"></i>
              </p>
              <form className="box-form">
                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-car"></i> &nbsp; Select Your Car
                    Type <b>*</b>
                  </label>

                  <select
                    value={selectedCar}
                    onChange={handleSelectChange}
                    style={{ color: "#444443" }}
                  >
                    <option>Select your car </option>
                    {carTypes.map((carType, index) => (
                      <option key={index} value={carType}>
                        {carType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-location-dot"></i> Pick-up{" "}
                    <b>*</b>
                  </label>
                  <select
                    value={pickUp}
                    onChange={handlePick}
                    style={{ color: "#444443" }}
                  >
                    <option>Select pick up location</option>
                   
                    <option>Larnaca Airport</option>
                    <option>Our Office: Nicosia City</option>
                  </select>
                </div>
                <div className="box-form__car-type">
                  <label>
                    <i className="fa-solid fa-location-dot"></i> Drop-off{" "}
                    <b>*</b>
                  </label>
                  <select
                    value={dropOff}
                    onChange={handleDrop}
                    style={{ color: "#444443" }}
                  >
                    <option>Select drop off location</option>
                    <option>Larnaca Airport</option>
                    <option>Our Office: Nicosia City</option>
                  </select>
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="picktime">
                    <i className="fa-regular fa-calendar-days "></i>
                    Pick-up Date <b>*</b>
                  </label>
                  <input
                    id="picktime"
                    value={pickTime}
                    onChange={handlePickTime}
                    type="date"
                  ></input>

                  <label htmlFor="droptime" style={{ marginTop: "13px" }}>
                    Pick-up Time <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={pickingTime}
                    onChange={handleTimeChange}
                    type="time"
                    className="input-time"
                    style={{ width: "150px" }}
                  />
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="droptime">
                    <i className="fa-regular fa-calendar-days "></i>
                    Drop-of Date <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={dropTime}
                    onChange={handleDropTime}
                    type="date"
                  ></input>

                  <label htmlFor="droptime" style={{ marginTop: "13px" }}>
                    Drop-off Time <b>*</b>
                  </label>
                  <input
                    id="droptime"
                    value={droppingTime}
                    onChange={handleTimeChange2}
                    type="time"
                    className="input-time"
                    style={{ width: "150px" }}
                  />
                </div>
              </form>

              <button
                onClick={openModal}
                type="submit"
                style={{
                  alignSelf: "center",
                  width: "260px",
                  marginTop: "14px",
                  backgroundColor: "#182b3a",
                  backgroundImage:
                    "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
                  transition: "box-shadow 0.3s ease-in-out",
                  borderColor: "blue",
                  borderRadius: "8px",
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}
      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div
          className="booking-modal__title"
          style={{
            backgroundColor: "#5078f2",
            backgroundImage: "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
          }}
        >
          <h2>Complete Reservation</h2>
          <i onClick={openModal} className="fa-solid fa-xmark"></i>
        </div>
        {/* message */}
        <div className="booking-modal__message">
          <h4 style={{ color: "blue" }}>
            <i className="fa-solid fa-circle-info"></i> Upon completing this
            reservation form:
          </h4>
          <p>
            Our staff will email you at the earliest with confirmation details.
          </p>
        </div>
        {/* car info */}
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5 style={{ color: "blue" }}>Location & Date</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickTime} / {formattedTimes}
                  </p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropTime} / {formattedTimes1}
                  </p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5 style={{ color: "blue" }}>
              <span>Car -</span> {selectedCar}
            </h5>
            {carImgUrl && <img src={carImgUrl} alt="car_img" style={{height:'170px'}} />}

            <h3>Price Information</h3>
            <div style={{backgroundColor:'#D3D3D3', padding:'2px', display:'flex',  justifyContent:'center', alignContent:'center', alignItems:'center'}}>
                    {messages.map((item) => (      
                    <table>
                      <thead style={{fontSize:'14px'}}>
                        <tr>
                          <th>Rented Days</th>
                          <th>_____</th>
                          <th>Price Per Day</th>
                        </tr>
                      </thead>
                      <tbody style={{fontSize:'12px'}}>
                       
                      <tr>
  <td>0 to 2 days:</td>
  <td></td>
  <td>€ {item.day0to2}</td>
</tr>
<tr>
  <td>3 days:</td>
  <td></td>
  <td>€ {item.day3}</td>
</tr>
<tr>
  <td>4 days:</td>
  <td></td>
  <td>€ {item.day4}</td>
</tr>
<tr>
  <td>5 days:</td>
  <td></td>
  <td>€ {item.day5}</td>
</tr>
<tr>
  <td>6 days:</td>
  <td></td>
  <td>€ {item.day6}</td>
</tr>
<tr>
  <td>7 days:</td>
  <td></td>
  <td>€ {item.day7}</td>
</tr>
<tr>
  <td>8 to 9 days:</td>
  <td></td>
  <td>€ {item.day8to9}</td>
</tr>
<tr>
  <td>10 days:</td>
  <td></td>
  <td>€ {item.day10}</td>
</tr>
<tr>
  <td>11 to 13 days:</td>
  <td></td>
  <td>€ {item.day11to13}</td>
</tr>
<tr>
  <td>14 days:</td>
  <td></td>
  <td>€ {item.day14}</td>
</tr>
<tr>
  <td>15 to 20 days:</td>
  <td></td>
  <td>€ {item.day15to20}</td>
</tr>
<tr>
  <td>21 to 31 days:</td>
  <td></td>
  <td>€ {item.day21to31}</td>
</tr>
                       
                      </tbody>
                    </table>


))}
</div>

          </div>

        </div>
        {/* personal info */}
        <div className="booking-modal__person-info" style={{}}>
          <h4 style={{ color: "blue" }}>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <div className="phoneInputContainer">
                  <label className="label">
                    Phone Number <b>*</b>
                  </label>
                  <div className="phoneInputWrapper">
                    <PhoneInput
                      international
                      defaultCountry="US"
                      value={phone}
                      onChange={setPhone}
                      placeholder="Enter your phone number"
                      className="phoneInput"
                    />
                  </div>
                  <p className="error-modal">This field is required.</p>
                </div>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="Enter Your Age"
                ></input>
                <p className="error-modal ">This field is required.</p>
                <span>


                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
              </span>
              

            </div>

            <div className="info-form__1col">

{/*
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
 /*}


              {/*
              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
              */}
            </div>
            <div className="info-form__2col">
              <span>
                <label>
                  Country <b>*</b>
                </label>
                <input
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your country"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>Baby/Booster/Infant Seat? (Per Rental)</label>

                <div>
                <select
                  value={seattype}
                  onChange={handleseatypet}
                  style={{
                    height: "35px",
                    fontSize: "15px",
                    paddingLeft: "10px", marginBottom:'10px',
                  }}
                >
                  <option value="">Choose option</option>
                  <option value="Baby Seat"> Baby Seat</option>
                  <option value="Booster Seat">Booster Seat</option>
                  <option value="Infant Seat">Infant Seat</option>
                </select>

                <select
                  value={babyseat}
                  onChange={handleseatnumber}
                  style={{
                    height: "35px",
                    fontSize: "15px",
                    paddingLeft: "10px", marginLeft:'15px',
                    width:'200px', marginBottom:'10px'
                  }}
                >
                  <option value="">Choose Quantity</option>
                  <option value="1">1 --  (35 Euro) </option>
                  <option value="2">2 --  (70 Euro)</option>
                  <option value="3">3 --  (105 Euro)</option>
                  <option value="4">4 --  (130 Euro)</option>
                </select>

                </div>

                <label>Additional Driver? (Per Rental)</label>
                <select
                  value={adddriver}
                  onChange={Addingdriver}
                  style={{
                    height: "35px",
                    fontSize: "15px",
                    paddingLeft: "10px", marginBottom:'10px'
                  }}
                >
                  <option value="">Choose option</option>
                  <option value="1">1 -- (25 Euro)</option>
                  <option value="2">2 -- (50 Euro)</option>
                  <option value="3">3 -- (75 Euro) </option>
                </select>


                <label>Insurance? (Choose Option) </label>
                <select
                  value={insurance}
                  onChange={handleinsurance}
                  style={{
                    height: "35px",
                    fontSize: "15px",
                    paddingLeft: "10px", 
                  }}
                >
                  
                  <option value={insuranceData}> Standard ({insuranceData})</option>
                  <option value="65 Euro Insurance">65 Euro (Reduce Deposit & Excess 85%) </option>
                  <option value="85 Euro Insurance">85 Euro (50 Euro Deposit, No Excess) </option>
                </select>



     <div style={{marginTop:'9px'}}>
        <label style={{fontSize:'15px', marginRight:'7px'}}>
        Tyre Insurance (€35)
      </label>
        <input
          type="checkbox"
          checked={tyreInsurance}
          onChange={() => setTyreInsurance(!tyreInsurance)}

        />
       </div>

       <div>

       <label style={{fontSize:'15px', marginRight:'7px'}}>
      Glass Insurance (€45)
      </label>
        <input
          type="checkbox"
          checked={glassInsurance}
          onChange={() => setGlassInsurance(!glassInsurance)}
        />
       
      </div>

      <div>
    <label style={{fontSize:'15px', marginRight:'7px'}}>
      Underbody Insurance (€25)
      </label>
        <input
          type="checkbox"
          checked={underbodyInsurance}
          onChange={() => setUnderbodyInsurance(!underbodyInsurance)}
        />
       
      </div>

              </span>

            </div>


            <div className="reserve-button">
              <button
                onClick={confirmBooking}
                style={{
                  backgroundColor: "#182b3a",
                  backgroundImage:
                    "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",marginBottom: '25px'
                }}
              >
                Reserve Now
              </button>

 <div className="row">  


<span className="col-6">
<h5 style={{fontSize:'18px'}}>Driver Age</h5>
<h5 style={{fontSize:'14px'}}>-------------------------</h5>
  <h5 style={{fontSize:'14px'}}>*Regular driver age: 25 to 70</h5>
  <h5 style={{fontSize:'14px'}}>*Young driver age: 21 to 24</h5>
  <h5 style={{fontSize:'14px'}}>*Senior driver age: 71 to 75 </h5>
  <h5 style={{fontSize:'14px'}}>*Young/Senior driver charge (5 Euro per day) </h5>
</span>

<span className="col-6">
<h5 style={{fontSize:'18px'}}>Requirements</h5>
<h5 style={{fontSize:'14px'}}>-------------------------</h5>
  <h5 style={{fontSize:'14px'}}>*3 Year Driving License</h5>
  <h5 style={{fontSize:'14px'}}>*Insurance options:</h5>
  <h5 style={{fontSize:'14px'}}> 1 - 65 Euro total price for rental reduce deposit and excess by 85%</h5>
  <h5 style={{fontSize:'14px'}}> 2 - 85 Euro reduce deposit to 50 euro zero excess</h5>
</span>

</div> 
            </div>



            
          </form>
        </div>
      </div>
    </>
  );
}

export default BookCar;
