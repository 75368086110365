function Footer() {



  function handleSubscribe() {
    alert('Thanks for subscribing!!!');
  }



  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">


          <ul className="footer-content__2">
              <li>Contact Us</li>
              
              <li style={{ fontSize: "14px", fontWeight:'bold' }}  >
                <a href="tel:+357 96 106055">
                &#x260E; +357 96 106055
                </a>
              </li>

              <li  style={{ fontSize: "14px", fontWeight:'bold' }} >
                <a
                  href="mailto: 
                  gakcarrentals@gmail.com"
                >
                
                  &#9993;   gakcarrentals@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px", fontWeight:'bold' }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/Ys4J8AMADtpBQZGT7"
                 // href="https://jephunneh.com/"
                >
                 &#128205; 
                Nicosia, Cyprus
                </a>
              </li>
              
            </ul>



            <ul className="footer-content__1">
              <li>
                <span style={{color:'purple'}}>GAK Car</span><span> Rental</span>
              </li>
             
             

              <li  style={{ fontSize: "13px" }}>
                We offers a big range of vehicles for all your car rental needs. We
                have the perfect car to meet your needs. 
              </li>

              <li>
                <a
                  style={{ fontSize: "14px"}}
                  target="_blank"
                  rel="noreferrer"
                  href="https://dev2code.com"
                >
                  <span>Website By:</span> 
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px"}}
                  target="_blank"
                  rel="noreferrer"
                  href="https://web4business.tech" 
                >
                  <span style={{color:'purple' }}>Qasim</span> 
                </a>
              </li>

  
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                <a href="https://maps.app.goo.gl/Ys4J8AMADtpBQZGT7">Our Location</a>
              </li>
              <li>
                <a href="/">Careers</a>
              </li>
              <li>
               <a href="/">Mobile</a>
              </li>
              

              <li>
              <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://admingakcarrental.netlify.app"
                 // 
                >
              
                   Admin  &#128187;
                </a>
              </li>


            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Sat: 8:00AM - 5:00PM</li>
              <li>Sun: Closed</li>
            </ul>

          
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
