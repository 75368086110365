import { useState, useEffect } from "react";
import CarBox from "./CarBox";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  deleteDoc, getDoc,
  addDoc, query, where,
  updateDoc,
} from "firebase/firestore";
import BookCar from "./BookCar.jsx";
import Modal from "react-modal";
import "./Modal.css"; // Import CSS file for modal styles
//Modal.setAppElement('#root'); // Set the root element for accessibility

function PickCar() {

  const [thisMonth, setThisMonth] = useState('lowseason');

  useEffect(() => {
    const currentMonth = new Date().getMonth(); // getMonth() returns 0 for January, 1 for February, etc.

    if (currentMonth >= 3 && currentMonth <= 9) { // April (3) to October (9)
      setThisMonth('highseason');
    } else { // November (10) to March (2)
      setThisMonth('lowseason');
    }
  }, []);


  const [messages, setMessages] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [selectedCar, setSelectedCar] = useState(null);


  useEffect(() => {
    const fetchItemId = async () => {
      try {
        // Create a query to search the carsdata collection by carname
        const q = query(collection(db, "carsdata"), where("name", "==", selectedCar));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setItemId(doc.id); // Set the item ID
        } else {
          console.log("No matching documents in carsdata collection.");
        }
      } catch (error) {
        console.error("Error fetching item ID:", error);
      }
    };

    fetchItemId();
  }, [selectedCar]);

  useEffect(() => {
    if (itemId) {
      const fetchData = async () => {
        try {
          // Create a reference to the sub-collection within the specific document
          const subCollectionRef = collection(db, "carsdata", itemId, thisMonth);

          const unsubscribe = onSnapshot(subCollectionRef, (snapshot) => {
            const filteredData = snapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
             

            setMessages(filteredData);
          });

          return () => {
            // Unsubscribe when component unmounts
            unsubscribe();
          };
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [db, itemId, selectedCar, setMessages, thisMonth]); 




  const [cars, setCars] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const priceData = [
    { days: "0 to 2 days", price: "price" },
    { days: "3 days", price: "price" },
    { days: "4 days", price: "price" },
    { days: "5 days", price: "price" },
    { days: "6 days", price: "price" },
    { days: "7 days", price: "price" },
    { days: "8 to 9 days", price: "price" },
    { days: "10 days", price: "price" },
    { days: "11 to 13 days", price: "price" },
    { days: "14 days", price: "price" },
    { days: "15 to 20 days", price: "price" },
    { days: "21 to 31 days", price: "price" },
  ];

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCars(data); // Update this line
        // setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [debouncedReserveNow, setDebouncedReserveNow] = useState(null);

  const handleReserveNow = () => {
    // Your logic for handling Reserve Now
    window.location.href = "#booking-section"; // Example: change URL
  };

  const debounce = (func, delay) => {
    if (debouncedReserveNow) {
      clearTimeout(debouncedReserveNow);
    }

    const timeout = setTimeout(() => {
      func();
      setDebouncedReserveNow(null);
    }, delay);

    setDebouncedReserveNow(timeout);
  };

 

  const handleClick = (car) => {
    // setSelectedCar('');
    setSelectedCar(car.name);
    debounce(handleReserveNow, 400);
  };

  const handleClick2 = () => {
    debounce(handleReserveNow, 400);
  };

  return (
    <section id="pick-section" className="pick-section">
      <div className="container">
        <div className="pick-container__title">
          <h2>Rental Fleet</h2>
          <p>
            A variety of cars available to cater any kind of trip need. Car
            types includes sedan, van, hatchback and suv.
          </p>
        </div>

        <div className="models-div">
          {cars.map((car) => (
            <div
              key={car.id}
              className="models-div__box"
              style={{
                backgroundColor: "#f9f9f9",
                border: "2px solid #ddd",
                borderRadius: "8px",
                transition: "transform 0.3s ease",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                position: "relative",
                overflow: "hidden",
                cursor: "pointer",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <div className="models-div__box__img">
                <img
                  src={car.img}
                  alt="car_img"
                  style={{
                    height: "230px",
                    width: "100%",
                    // objectFit: "cover",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    "@media(max-width: 500px)": {
                      // Media query for screens less than 500px width
                      height: "auto", // Allow the height to adjust according to the aspect ratio
                      width: "100%", // Take full width of the container
                    },
                  }}
                />
                <div
                  className="models-div__box__img-overlay"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {car.name} 
                </div>
              </div>
              <div
                className="models-div__box__descr"
                style={{ padding: "15px" }}
              >
                <div
                  className="models-div__box__descr__price"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="models-div__box__descr__btn"
                    style={{
                      height: "25px",
                      width: "60%",
                      backgroundColor: "#182b3a",
                      //backgroundImage:
                      //  "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "bold",
                      marginTop: "10px",
                      transition: "background-color 0.3s ease",
                      fontSize: "14px", 
                    }}
                    onClick={() => {
                      setModalIsOpen(true);
                      setSelectedCar(car.name);
                    }}
                  >
                    Click For Price Chart
                  </button>

                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Price Modal"
                    className="price-modal"
                    overlayClassName="price-modal-overlay"
                  >
                    <h3>Price Information</h3>
                    {messages.map((item) => (      
                    <table style={{width:'210px'}}>
                      <thead style={{fontSize:'14px'}}>
                        <tr>
                          <th>Days</th>
                          <th>...</th>
                          <th>Price Per Day</th>
                        </tr>
                      </thead>
                      <tbody style={{fontSize:'14px'}}>
                       
                      <tr>
  <td>0 to 2 days:</td>
  <td></td>
  <td>€ {item.day0to2}</td>
</tr>
<tr>
  <td>3 days:</td>
  <td></td>
  <td>€ {item.day3}</td>
</tr>
<tr>
  <td>4 days:</td>
  <td></td>
  <td>€ {item.day4}</td>
</tr>
<tr>
  <td>5 days:</td>
  <td></td>
  <td>€ {item.day5}</td>
</tr>
<tr>
  <td>6 days:</td>
  <td></td>
  <td>€ {item.day6}</td>
</tr>
<tr>
  <td>7 days:</td>
  <td></td>
  <td>€ {item.day7}</td>
</tr>
<tr>
  <td>8 to 9 days:</td>
  <td></td>
  <td>€ {item.day8to9}</td>
</tr>
<tr>
  <td>10 days:</td>
  <td></td>
  <td>€ {item.day10}</td>
</tr>
<tr>
  <td>11 to 13 days:</td>
  <td></td>
  <td>€ {item.day11to13}</td>
</tr>
<tr>
  <td>14 days:</td>
  <td></td>
  <td>€ {item.day14}</td>
</tr>
<tr>
  <td>15 to 20 days:</td>
  <td></td>
  <td>€ {item.day15to20}</td>
</tr>
<tr>
  <td>21 to 31 days:</td>
  <td></td>
  <td>€ {item.day21to31}</td>
</tr>
                       
                      </tbody>
                    </table>


))}




                    <div
                      style={{
                        display: "flex",
                         justifyContent: "space-between",
                        marginTop: "8px",
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: "red",
                          padding: "5px",
                          color: "white",
                          fontSize: "14px",
                          fontWeight: "bold", marginLeft:'30px'
                        }}
                        onClick={closeModal}
                      >
                        Close
                      </button>

                      <button
                        style={{
                          backgroundColor: "green",
                          padding: "5px",
                          color: "white",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        
                        onClick={() => {
                          closeModal()
                          handleClick2()
                        }}
                      >
                       Book Car
                      </button>



                    </div>
                  </Modal>
                </div>
                <div className="models-div__box__descr__details">
                  <span style={{ fontSize: "12px", margin: "2px" }}>
                    &#x2022; {car.transmission}
                  </span>
                  <span style={{ fontSize: "12px", margin: "2px" }}>
                    &#x2022; € {car.fuel} deposit
                  </span>
                  <span style={{ fontSize: "12px", margin: "2px" }}>
                    &#x2022; {car.air}
                  </span>
                </div>
                <button
                  className="models-div__box__descr__btn"
                  style={{
                    height: "40px",
                    width: "100%",
                    backgroundColor: "#182b3a",
                    backgroundImage:
                      "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    color: "white",
                    fontWeight: "bold",
                    marginTop: "10px",
                    transition: "background-color 0.3s ease",
                    fontSize: "15px",
                  }}
                  onClick={() => handleClick(car)}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#20a4f3")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "#182b3a")
                  }
                >
                  Reserve Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {<BookCar carName={selectedCar} />}
    </section>
  );
}

export default PickCar;

/*selectedCar && <BookCar carName={selectedCar} /> */
