import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";
import Box2 from "../images/about/icon2.png";
import Box3 from "../images/about/icon3.png";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>About Us - Gak Car Rental </title>

        <meta
          name="description"
          content="Discover best cyprus car rental deals in Nicosia and Larnaca airport. Your affordable car renting experience awaits with Gak Car Rental Nicosia Cyprus!."
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="about-page">
        <HeroPages name="About" />
        <div className="container">
          <div className="about-main">
            <img
              className="about-main__img"
              src={'https://images.pexels.com/photos/7144174/pexels-photo-7144174.jpeg?auto=compress&cs=tinysrgb&w=600'}
              alt="car-rental dealership"
            />
            <div className="about-main__text">
              <h3>About Company</h3>
              <h2>The best car rental experience awaits you.</h2>
              <p>
              Discover the convenience of exploring Cyprus's beautiful landscapes with GAK Car Rental. Located in Nicosia, we offer a wide range of reliable vehicles to suit your needs. Whether you're touring scenic landscapes or navigating city streets, GAK Car Rental ensures a hassle-free experience with well-maintained vehicles, competitive pricing, and a dedication to customer satisfaction. Conveniently located near Larnaca airport, experience the ease of reliable transportation with GAK Car Rental in Cyprus. Book now for the best deals!

              </p>
              <div className="about-main__text__icons">
                <div className="about-main__text__icons__box">
                  <img src={Box1} alt="car-icon" />
                  <span>
                    <h4>25+</h4>
                    <p>Cars </p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box2} alt="car-icon" />
                  <span>
                    <h4>1</h4>
                    <p>
                      <a
                        href="https://maps.app.goo.gl/Ys4J8AMADtpBQZGT7"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rental Outlet
                      </a>
                    </p>
                  </span>
                </div>
                <div className="about-main__text__icons__box">
                  <img src={Box3} alt="car-icon" className="last-fk" />
                  <span>
                    <h4>1</h4>
                    <p>Repair Shop</p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/*   <PlanTrip /> */}
         
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Book a car by getting in touch with us</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3 style={{color:'#00FFFF'}}>+357 96 106055</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
