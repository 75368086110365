import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import HeroPages from "../components/HeroPages";
import React from "react";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";
import { Helmet } from "react-helmet";
import axios from 'axios';


function Contact() {
  {
    
    /*
  const carDatas = [
    
  
    {
      name: "Nissan Serena",
      price: "00",
      img: 'https://getrentacar.com/storage/cache/images/960-640-100-fit-103093.jpeg',
      air: "Van",
      transmission: "Automatic",
      fuel: "2000",
    },
  
    {
      name: "Suzuki Alto",
      price: "00",
      img: 'https://cache4.pakwheels.com/system/car_generation_pictures/6013/original/Suzuki_Alto_-_PNG.png?1635945100',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },

 {
      name: "Mazda Premacy",
      price: "00",
      img: 'https://newsroom.mazda.com/en/publicity/release/2010/201012/101215a.jpg',
      air: "MPV",
      transmission: "Automatic",
      fuel: "2000",
    },


{
      name: "Nissan Juke",
      price: "00",
      img: 'https://d2ivfcfbdvj3sm.cloudfront.net/7fc965ab77efe6e0fa62e4ca1ea7673bb25b4652031e3d8e88cb/stills_0640_png/MY2017/11738/11738_st0640_116.png',
      air: "SUV",
      transmission: "Automatic",
      fuel: "2000",
    },

{
      name: "Nissan Note",
      price: "00",
      img: 'https://www.motortrend.com/uploads/sites/10/2019/06/2019-nissan-versa-note-sv-5door-hatchback-angular-front.png?fit=around%7C875:492',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },

{
      name: "Nissan March",
      price: "00",
      img: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi8oWP6uaBwQ_GxIKui00RS3Vxe7fKut0xyr38heQpMWrYJci6hoqV81MzbodNbQ2IDjxTZiYK1b4Y4tpd3jZNNRWNXuUmogZgualiy2Ms9YuGhXdMPnqBmG5KFXX1BOcgTnycgbFQacj8Y/s640-rw/Nissan-March-2019.JPG',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },

{
      name: "Nissan Cube",
      price: "00",
      img: 'https://cars.usnews.com/static/images/Auto/izmo/353290/2013_nissan_cube_angularfront.jpg',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },
    
{
      name: "Fiat Tipo",
      price: "00",
      img: 'https://www.fiat.com.cy/content/dam/fiat/cross/models/tipo_2020/opening/desktop/figurini/sedan/tipo/Tipo-Model-page-GelatoWhite-Car_Desktop-680x430.png',
      air: "Sedan",
      transmission: "Automatic",
      fuel: "1000",
    },


    {
      name: "Nissan Cube",
      price: "00",
      img: 'https://cdn.wheel-size.com/automobile/body/toyota-corolla-axio-2017-2021-1602239070.114188.jpg',
      air: "Hatchback",
      transmission: "Sedan",
      fuel: "1000",
    },

{
      name: "Volkswagen Up",
      price: "00",
      img: 'https://cars.japanesecartrade.com/vehicle_image/20351522_201602z.jpg',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },

{
      name: "Mazda Demio",
      price: "00",
      img: 'https://cars.japanesecartrade.com/vehicle_image/10252010_201808y.jpg',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },

    {
      name: "Honda Fit",
      price: "00",
      img: 'https://platform.cstatic-images.com/in/v2/stock_photos/4f7a628e-4f6c-4ea4-b32f-3bdb82cbd18b/dd77a9fa-afc6-4dc4-baa2-bdddb8383dac.png',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },



{
      name: "Kia Picanto",
      price: "00",
      img: 'https://press.kia.com/content/dam/kiapress/EU/Pressreleases/2020/kia_pressrelease_2020_new-picanto/kwcms_620x466_JAPEGTL_INDOOR_FRONT.jpg.crop.620.466.high.jpg',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },

{
      name: "Skoda Fabia",
      price: "00",
      img: 'https://stimg.cardekho.com/images/car-images/large/Skoda/Febia/Fabia9.jpg?impolicy=resize&imwidth=420',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },

    {
      name: "Suzuki Swift",
      price: "00",
      img: 'https://cars.japanesecartrade.com/vehicle_image/10552013_201406x.jpg',
      air: "Hatchback",
      transmission: "Automatic",
      fuel: "800",
    },




  
  ];

  const addCarsToFirestore = async () => {
    const carsCollection = collection(db, 'carsdata');
  
    for (const car of carDatas) {
      try {
        await addDoc(carsCollection, car);
        console.log(`Car ${car.name} added to Firestore`);
      } catch (error) {
        console.error(`Error adding car ${car.name} to Firestore:`, error);
      }
    }
  };
  
 
    useEffect(() => {
      addCarsToFirestore();
    }, []); // This will run the function only once on component mount
  
*/
  
  }

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [question, setQuestion] = React.useState("");

  const postmsg = async () => {
    try {
      // Validate input fields
      if (!name || !email || !question) {
        window.alert("Please Fill All Fields!");
        return;
      }
  
      // Get the current date
      const currentDate = new Date();
  
      // Format the date as "YYYY-MM-DD"
      const formattedDate = currentDate.toISOString().split("T")[0];
  
      // Add a new document to the "Messages" collection with the specified fields
      await db.collection("Messages").add({
        cname: name,
        cemail: email,
        cquestion: question,
        date: formattedDate,
      });
  
      window.alert("Your message is sent!");
  
      // Reload the page
      window.location.reload();
      
      console.log("Contact Details Posted!");
    } catch (error) {
      // Log any errors to the console
      console.error(error);
      window.alert("An error occurred. Please try again later.");
    }
  };

  

  return (
    <>

     <Helmet>
        <title>
          Contact Us - Gak Car Rental 
        </title>

        <meta
          name="description"
          content="Discover the budget car rental in cyprus deals. Located in Nicosia city near Larnaca airport. Your ultimate car renting experience awaits with Gak Car Rental in Nicosia Cyprus!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">


          <div className="row">
            <div className="col-md-6">
              <div className="contact-div">
                <div className="contact-div__text">
                  <a
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "green",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://maps.app.goo.gl/Ys4J8AMADtpBQZGT7"
                  >
                    Click For Google Maps Location
                  </a>

                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="tel:+357 96 106055"
                  >
                    &#x260E; +357 96 106055
                  </a>
                  <a
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                    href="mailto:  gakcarrentals@gmail.com"
                  >
                    &#9993; Click To Email Us
                  </a>
                  <a style={{ fontWeight: "bold", fontSize: "18px" }} href="/">
                    &#128205;  Arch. Makariou III 113, Dromolaxia 7020, Cyprus
                  </a>
                  <br></br>
                  <h3>Need additional information?</h3>
                  <p>
                  Explore convenient and affordable vehicle rental with GAK Car Rental. Our diverse fleet of well-maintained vehicles ensures reliable transportation solutions for your exploration needs in the beautiful Cyprus. Enjoy seamless travel experiences with our GAK Car Rental – your trusted partner for exploring Cyprus. Book now at <a
                        href="https://maps.app.goo.gl/Ys4J8AMADtpBQZGT7"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        office
                      </a>    for an unmatched journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-div__form">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    postmsg();
                  }}
                >
                  <label>
                    Full Name <b>*</b>
                  </label>
                  <input
                    type="text"
                    placeholder='E.g: "Joe Shmoe"'
                    onChange={(e) => setName(e.target.value)}
                  />

                  <label>
                    Email <b>*</b>
                  </label>
                  <input
                    type="email"
                    placeholder="youremail@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <label>
                    Tell us about it <b>*</b>
                  </label>
                  <textarea
                    placeholder="Write Here.."
                    onChange={(e) => setQuestion(e.target.value)}
                  ></textarea>

                  <button type="submit" style={{backgroundColor:'#182b3a',   backgroundImage: 'linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)', borderColor:'blue'}}   >
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3 style={{color:'#00FFFF'}}>+357 96 106055</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
