function Banner() {
  return (
    <>
      <section className="banner-section"style={{ backgroundColor: "#2234ae",
    backgroundImage: "linear-gradient(315deg, #2234ae 0%, #191714 74%)"}} >
        <div className="container">

          <div className="row"> 

          <div className="col-12"> 
          <div className="banner-content">
            <div className="banner-content__text" >
              <h2>Best Car Rental In Nicosia Cyprus!</h2>
              <p>
                Book your car now at  <span style={{color:'#00FFFF'}} >+357 96 106055</span> 
              </p>
            </div>
          </div>
          </div>

          <div className="col-12" style={{height:'270px'}}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3423.853209239777!2d33.599416814875345!3d34.86912715930715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e09ceb23fecc8d%3A0x8c7dbb4866ea8442!2sGak%20Car%20Rentals!5e0!3m2!1sen!2s!4v1637195737272!5m2!1sen!2s"
        width="100%"
        height="100%"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="eager"
      ></iframe>
    </div>



          </div>

        </div>
      </section>
    </>
  );
}

export default Banner;
