import Hero from "../components/Hero";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
//import Models from "./Models";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>
        Home - Gak Car Rental
        </title>

        <meta
          name="description"
          content="Discover the best car rental deals with Gak Car Rental. Providing top-quality car renting services in Nicosia and Larnaca airport. Your ultimate car renting experience awaits with Gak Car Rental Cyprus!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <Hero />
      <PickCar />
      
     
     
      <ChooseUs />
      <Banner />
      <Testimonials />
      <Faq />
      <Footer />
    </>
  );
}

export default Home;
