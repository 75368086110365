

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'
import 'firebase/compat/firestore';

const firebaseConfig = {

    apiKey: "AIzaSyBfUZoCgfGxHBY92okRqg2aALD7393E040",
  
    authDomain: "gak-car-rental.firebaseapp.com",
  
    projectId: "gak-car-rental",
  
    storageBucket: "gak-car-rental.appspot.com",
  
    messagingSenderId: "577671987409",
  
    appId: "1:577671987409:web:758c235dfd6c5761fcc5f0"
  
  };
  
  
let app;

if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig)
} else {
    app = firebase.app();
}

const db = app.firestore();


export {db} 